// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import select2 from "select2";
import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
import "channels"
// require("./datatables")
Rails.start()
//Turbolinks.start()
//ActiveStorage.start()

function initFilters() {
  var options = {
    autoclose: true,
    language: 'it',
    format: 'yyyy-mm-dd',
    todayBtn: 'linked',
    clearBtn: true,
    todayHighlight: true
  };
  $('#filters_start_date').datepicker(options);
  $('#filters_end_date').datepicker(options);
}

function initOspedale(){
  $('#filters_hospital').select2({
    placeholder: "Seleziona i presidi",
    theme: "bootstrap",
  });
  $('#daily_report_hospital_id').select2({
    placeholder: "Seleziona il presidio",
    theme: "bootstrap",
  });
}



function burger(){
  $('.fa-bars').on('click', function(){
    $('body').toggleClass('nav-mobile-open');
  });
}



function form(){
  var covid = $('#daily_report_ps_pazienti_covid');
  var test = $('#daily_report_ps_test_in_corso');
  var totali = $('#daily_report_ps_pazienti_totali');
  var no = $('#daily_report_ps_pazienti_no_covid');

  function setNoCovid(){
    if (totali.val() && covid.val() && test.val()) {
      var calc = Number(totali.val()) - (Number(covid.val()) + Number(test.val()));
      no.val(calc);
    }
  }

  covid.on('change', setNoCovid);
  test.on('change', setNoCovid);
  totali.on('change', setNoCovid);

  var bording = $('#daily_report_ps_boarding_covid');
  var bordingNo = $('#daily_report_ps_boarding_no_covid');
  var boardingTot = $('#daily_report_ps_boarding_totale');
  function setBoarding(){
    if (bording.val() && bordingNo.val()) {
      var calc = Number(bording.val()) + Number(bordingNo.val());
      boardingTot.val(calc);
    }
  }
  bording.on('change', setBoarding);
  bordingNo.on('change', setBoarding);
}

function datePickerForReport(type, with_summary= false) {
  var options = {
    autoclose: true,
    language: 'it',
    format: 'yyyy-mm-dd',
    todayBtn: 'linked',
    clearBtn: true,
    todayHighlight: true
  };
  var dp = $('.' + type).datepicker(options);
  dp.datepicker('show');
  
  dp.on('hide', function(e) {
    var params_string = 'format=xlsx&filter=daily&with_summary='+with_summary+'&report_date='+e.date
    if(e.date == undefined) {
      return;
    }
    else {
      window.location.href = '/downloads?'+params_string
    }
  });
}

function downloadReport() {
  $('.daily_data').on('click', function(){
    datePickerForReport('daily_data', false);
  });
  $('.daily_report').on('click', function(){
    datePickerForReport('daily_report', true);
  });
}




$(document).ready(function(){
  burger();
  initOspedale();
  form();
  initFilters();
  downloadReport();
});